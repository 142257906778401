import { createApp } from 'vue'
import App from './App.vue'
import apiAxios from '../../config/request.js'
import baseFunc from '../../config/base_func.js';
import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue';

const app = createApp(App);
app.config.globalProperties.$apiRequest = apiAxios; 
app.config.globalProperties.$baseFunc = baseFunc; 
//将实例挂载至节点
app.use(Antd).mount('#text_to_audio_root');
